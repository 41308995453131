:root {
  --main-bg: #1d67de;
}

input:focus, button:focus {
  border: 1px solid var(--main-bg) !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--main-bg) !important;
  border-color: var(--main-bg) !important;
}

.card, .btn, input {
  border-radius: 0 !important;
}

.background-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('bg.jpg');
  background-size: cover;
  background-position: center;
}

.background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.content {
  z-index: 2;
}

.container {
  padding-top: 15px;
  min-width: 80vw !important;
}

.form-group label {
  font-size: 13px !important;
}

textarea:empty {
  height: 28px;
}

.print {
  display: none;
}

/* styles.css */

/* PrintComponent.css */

@media print {
  /* @page {
    size: portrait A5;
    margin: 1cm;
  } */

  body {
    margin: 0;
    padding: 0;
    /* transform: rotate(90deg); */
  }

  .a5-container {
    transform: rotate(-90deg);
    page-break-before: always;
    /* height: 210mm;
    width: 140mm; */
  }

  .print {
    display: block;
  }

  .konten {
    display: none;
  }

  /* body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-size: 10px;
    max-width: 794px;
    max-height: 140mm;
    min-width: 794px;
    min-height: 140mm;
    color: black;
  } */

  .div-print {
    /* width: 210mm;
    height: 140mm;
    margin: 0 auto;
    position: relative; */
    /* transform: rotate(90deg); */
    transform-origin: top left;
    white-space: nowrap;
  }

  .page-break {
    page-break-before: always;
  }

  .div-print .bottom-element {
    position: absolute;
    bottom: 0;
    left: 5%;
    transform: translateX(-50%);
  }

  /* .div-print .div-hide {
    color: red;
    position: absolute;
    bottom: 0;
    left: 5%;
    transform: translateX(-50%);
  } */

  .div-hide:last-child {
    color: black;
  }
}